/* eslint-disable */
import useGlobal from '../hooks/useGlobal'
import { useState, Fragment, useEffect } from 'react'

import { Combobox, Transition, Dialog } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import moment from 'moment'
import ReactGA from 'react-ga4'

const Step1 = () => {
  const { progress, quote, saveProgress, goToStep, api, token } = useGlobal()

  const [selected, setSelected] = useState({})
  const [schoolList, setSchoolList] = useState([])
  const [query, setQuery] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [showHelp, setShowHelp] = useState(false)

  const [hearAboutUs, setHearAboutUs] = useState('--')

  const [showOther, setShowOther] = useState(false)
  useEffect(() => {
    if (quote.marketing.hearAboutUs == 'Other') {
      setShowOther(true)
    } else {
      setShowOther(false)
    }
    setHearAboutUs(quote.marketing.hearAboutUs)
  }, [quote])

  const [noSchool, setNoSchool] = useState(false)
  const [newSchool, setNewSchool] = useState({
    contact_name: '',
    contact_tel: '',
    name: '',
    postcode: '',
  })

  const filteredList =
    query === ''
      ? schoolList
      : schoolList.filter(
          (school) =>
            school.name
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')) ||
            school.postcode
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  useEffect(() => {
    if (query.length > 0) {
      fetchData()
    }

    if (query.length === 0) {
      setSchoolList([])
    }
  }, [query])

  const fetchData = async () => {
    try {
      const response = await axios.get(
        api +
          'school-search' +
          '/' +
          query +
          '/AdmxJxJxB942LZNAANMOMqyG4AtOcf7l'
      )
      if (!response.data.data) {
        setSchoolList([])
        return
      }
      setSchoolList(response.data.data)
    } catch (e) {
      console.log(e)
    }
  }

  const updateSelected = (e) => {
    setSelected(e)

    progress.contact_organisation = e.name
    progress.contact_id = e.id
    quote.name = e.name
    quote.id = e.id
    if (quote.type === 'single') {
      quote.ref = e.urn
    } else {
      quote.ref = e.id.split('-')[0]
    }

    if (e.postcode === 'Unknown') {
      quote.postcode = 'Unknown'
    } else {
      quote.postcode = e.postcode
    }
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const nextStep = async (e) => {
    e.preventDefault()

    if (progress.marketing.hearAboutUs == '--') {
      setIsOpen(true)
      return
    }
    try {
      const response = await axios.post(api + 'enquiry', {
        token: token,
        enquiry_all: JSON.stringify(progress),
      })
      progress.enquiryID = response.data.data
      if (quote.type === 'multi') {
        quote.MATpolicyManagement = 'individual'
        quote.MATcoverLevel = 'individual'
      }

      if (quote.type !== 'multi') {
        quote.schools = [
          {
            id: quote.id,
            name: quote.name,
            status: 'draft',
            acceptance: [],
            premiumSecondary: 0,
            discountReason: '',
            loadingReason: '',
            loading: 0,
            discount: 0,
            premium: 0,
            ref: quote.ref,
            absences: [],
            coverStartDate: moment().format('YYYY-MM-DD'),
            additionalCover: '',
            coverEndDate: '',
            autoDiscount: {
              total: 0,
              good_school: 0,
              good_area: 0,
              FTE_ratio: 0,
              MAT: 0,
            },
            absenceAverage: 100,
            items: [
              {
                breakdown: {
                  base: 0,
                  beforeAutoDiscount: 0,
                  withAutoDiscount: 0,
                  premium: 0,
                  maternity: '0.00',
                },
                staffType: 'Teachers',
                staffGrade: 'A',
                selected: true,
                benefit: 0,
                fte: '0.00',
                excess: '--',
                period: '--',
                stress: '--',
                maternity: '0.00',
                maternityCover: 'Excluded',
                premium: 0,
              },
              {
                breakdown: {
                  base: 0,
                  beforeAutoDiscount: 0,
                  withAutoDiscount: 0,
                  premium: 0,
                  maternity: '0.00',
                },
                staffType: 'Support Staff',
                staffGrade: 'B',
                selected: true,
                benefit: 0,
                fte: '0.00',
                excess: '--',
                period: '--',
                stress: '--',
                maternity: '0.00',
                maternityCover: 'Excluded',
                premium: 0,
              },
            ],
          },
        ]
      } else {
        let url = api + 'academy/trust/' + quote.id
        if (quote.matCreated) {
          url = api + 'academy/trust/' + 0
        }
        try {
          const response2 = await axios.get(url)

          let array = []
          for (let i = 0; i < response2.data.data.length; i++) {
            array.push({
              ref: response2.data.data[i]['academy_urn'],
              id: response2.data.data[i]['academy_id'],
              postcode: response2.data.data[i]['academy_postcode'],
              name: response2.data.data[i]['academy_name'],
              label:
                response2.data.data[i]['academy_name'] +
                ' - ' +
                response2.data.data[i]['academy_postcode'],
            })
          }
          let schoollist = array

          array = []
          let ids = []
          let schools = schoollist
          for (let i = 0; i < schools.length; i++) {
            ids.push(schools[i].id)
            array.push({
              id: schools[i].id,
              name: schools[i].name,
              status: 'draft',
              acceptance: [],
              premiumSecondary: 0,
              discountReason: '',
              loadingReason: '',
              loading: 0,
              discount: 0,
              premium: 0,
              ref: schools[i].ref,
              absences: [],
              coverStartDate: moment().format('YYYY-MM-DD'),
              additionalCover: '',
              coverEndDate: '',
              autoDiscount: {
                total: 0,
                good_school: 0,
                good_area: 0,
                FTE_ratio: 0,
                MAT: 0,
              },
              absenceAverage: 100,
              items: [
                {
                  breakdown: {
                    base: 0,
                    beforeAutoDiscount: 0,
                    withAutoDiscount: 0,
                    premium: 0,
                    maternity: '0.00',
                  },
                  staffType: 'Teachers',
                  staffGrade: 'A',
                  selected: true,
                  benefit: 0,
                  fte: '0.00',
                  excess: '--',
                  period: '--',
                  stress: '--',
                  maternity: '0.00',
                  maternityCover: 'Excluded',
                  premium: 0,
                },
                {
                  breakdown: {
                    base: 0,
                    beforeAutoDiscount: 0,
                    withAutoDiscount: 0,
                    premium: 0,
                    maternity: '0.00',
                  },
                  staffType: 'Support Staff',
                  staffGrade: 'B',
                  selected: true,
                  benefit: 0,
                  fte: '0.00',
                  excess: '--',
                  period: '--',
                  stress: '--',
                  maternity: '0.00',
                  maternityCover: 'Excluded',
                  premium: 0,
                },
              ],
            })
          }
          quote.schools = array

          if (quote.matCreated) {
            try {
              await axios.post(api + 'academy/associate', {
                token: token,
                ids: ids,
                trust_id: quote.id,
              })
            } catch (error) {}
          }
        } catch (error) {}
      }
    } catch (error) {}

    save()
  }

  const save = () => {
    saveProgress()
    goToStep(2, 'complete')
  }

  const submitQuoteRequest = async (e) => {
    e.preventDefault()
    try {
      await axios.post(api + 'help-request', {
        token: token,
        name: newSchool.contact_name,
        position: '',
        email: '',
        number: newSchool.contact_tel,
        acc_name: newSchool.name,
        acc_postcode: newSchool.postcode,
        dpt: 'Mutual',
      })
      ReactGA.event({
        category: 'general',
        action: 'quote-request',
        label: 'QuoteRequestClicked',
      })
      setShowHelp(true)
    } catch (error) {}

    setNewSchool({
      contact_name: '',
      contact_tel: '',
      name: '',
      postcode: '',
    })
  }

  useEffect(() => {
    if (quote.postcode && quote.name) {
      setSelected({
        name: quote.name,
        postcode: quote.postcode,
      })
    }
  }, [quote])

  return (
    <div className="w-full flex flex-col items-center px-2 md:px-0 py-6 md:py-12">
      <h2 className="text-font-primary font-bold text-xl sm:text-2xl lg:text-3xl mb-8 lg:mb-12 text-center">
        {quote.type === 'single' ? 'School Details' : 'Trust/MAT Details'}
        <span className="block text-sm font-bold mt-2 text-emerald-600">
          Selected:{' '}
          {selected.name && selected.name !== ''
            ? `${selected.name} - ${selected.postcode}`
            : 'None'}
        </span>
      </h2>

      <div className="flex flex-col justify-center max-w-3xl w-full">
        <div className="input-group">
          <label htmlFor="schoolSearch">
            {quote.type === 'single'
              ? 'Search for your School / Academy below'
              : 'Search for your Multi Academy Trust below'}

            <span className="required">*</span>
          </label>
          <Combobox
            value={selected}
            onChange={(e) => {
              updateSelected(e)
            }}
          >
            <div className="relative">
              <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                <Combobox.Input
                  className="w-full border-none py-3 pl-3 pr-10 text-base md:text-sm leading-5 text-gray-900 focus:ring-0"
                  displayValue={(school) => {
                    if (!school.urn) {
                      return ''
                    }
                    return `${school.urn} - ${school.name} - ${school.postcode}`
                  }}
                  onChange={(event) => setQuery(event.target.value)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2">
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="text-base text-gray-400"
                  />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options className="absolute mt-1 max-h-60 z-10 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredList?.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                      Nothing found.
                    </div>
                  ) : (
                    filteredList?.map((data) => {
                      if (quote.type == 'single' && !data.urn) {
                        return null
                      }

                      if (quote.type == 'multi' && data.urn) {
                        return null
                      }
                      return (
                        <Combobox.Option
                          key={data.id}
                          className={({ active }) =>
                            `relative cursor-pointer select-none py-2 pl-4 pr-4 ${
                              active
                                ? 'bg-teal-600 text-white'
                                : 'text-gray-900'
                            }`
                          }
                          value={data}
                        >
                          {({ selected, active }) => {
                            let text = ''
                            if (quote.type == 'multi') {
                              text = `${data.name} - ${data.postcode}`
                            }
                            if (quote.type == 'single') {
                              text = `${data.urn} - ${data.name} - ${data.postcode}`
                            }
                            return (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? 'font-medium' : 'font-normal'
                                  }`}
                                >
                                  {text}
                                </span>
                                {selected ? (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                      active ? 'text-white' : 'text-teal-600'
                                    }`}
                                  >
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      className="text-xs"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )
                          }}
                        </Combobox.Option>
                      )
                    })
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
        <div>
          <p
            className="text-gray-400 text-xs mt-1 cursor-pointer hover:text-gray-500 inline-block w-auto"
            onClick={() => setNoSchool(true)}
          >
            {quote.type === 'single'
              ? 'Unable to find your School / Academy?'
              : 'Unable to find your Multi Academy Trust?'}
          </p>
          {noSchool && (
            <>
              {showHelp ? (
                <div>
                  <div className="checkmark fade-in" style={{ width: 120 }}>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 161.2 161.2"
                      enableBackground="new 0 0 161.2 161.2"
                    >
                      <path
                        className="path"
                        fill="none"
                        stroke="#3dc654"
                        strokeMiterlimit="10"
                        d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
        c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
        c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"
                      />
                      <circle
                        className="path"
                        fill="none"
                        stroke="#3dc654"
                        strokeWidth="4"
                        strokeMiterlimit="10"
                        cx="80.6"
                        cy="80.6"
                        r="62.1"
                      />
                      <polyline
                        className="path"
                        fill="none"
                        stroke="#3dc654"
                        strokeWidth="6"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        points="113,52.8 
        74.1,108.4 48.2,86.4 "
                      />

                      <circle
                        className="spin"
                        fill="none"
                        stroke="#3dc654"
                        strokeWidth="4"
                        strokeMiterlimit="10"
                        strokeDasharray="12.2175,12.2175"
                        cx="80.6"
                        cy="80.6"
                        r="73.9"
                      />
                    </svg>
                  </div>

                  <h3 className="text-base sm:text-lg font-bold text-center text-primary mt-4">
                    Thank you for your request. One of our team will be in
                    contact shortly.
                  </h3>
                </div>
              ) : (
                <form className="mt-4" onSubmit={submitQuoteRequest}>
                  <p className="text-sm font-semibold text-primary mb-1">
                    Enter your details below and we will contact you for a quote
                  </p>
                  <div className="flex space-x-4 mb-4">
                    <div className="input-group">
                      <label htmlFor="noSchool-contact_name">
                        Contact Name<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        id="noSchool-contact_name"
                        name="noSchool-contact_name"
                        className="cm-input"
                        onChange={(e) =>
                          setNewSchool({
                            ...newSchool,
                            contact_name: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="noSchool-contact_tel">
                        Contact Number<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        id="noSchool-contact_tel"
                        name="noSchool-contact_tel"
                        className="cm-input"
                        onChange={(e) =>
                          setNewSchool({
                            ...newSchool,
                            contact_tel: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <div className="input-group">
                      <label htmlFor="noSchool-name">
                        {quote.type === 'single'
                          ? 'School/Academy Name'
                          : 'Trust Name'}
                        <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        id="noSchool-name"
                        name="noSchool-name"
                        className="cm-input"
                        onChange={(e) =>
                          setNewSchool({ ...newSchool, name: e.target.value })
                        }
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="noSchool-postcode">
                        Postcode<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        id="noSchool-postcode"
                        name="noSchool-postcode"
                        className="cm-input"
                        onChange={(e) =>
                          setNewSchool({
                            ...newSchool,
                            postcode: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex justify-end items-center space-x-4 mt-4">
                    <button className="btn small bg-primary hover:bg-primary-hover text-white">
                      Request a Quote
                    </button>
                    <button
                      role="button"
                      className="btn small bg-red-500 hover:bg-red-600 text-white"
                      onClick={() => setNoSchool(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}
            </>
          )}
        </div>
      </div>

      <hr className="border-slate-300 w-5/6 my-8 mx-auto" />

      <h2 className="text-font-primary font-bold text-xl sm:text-2xl lg:text-3xl mb-0 md:mb-4">
        Contact Details
      </h2>

      <div className="flex flex-col justify-center max-w-3xl w-full">
        <div>
          <form className="mt-4" onSubmit={nextStep}>
            <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 mb-2 md:mb-4">
              <div className="input-group">
                <label htmlFor="contact_name">
                  Your Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  required
                  id="contact_name"
                  name="contact_name"
                  className="cm-input"
                  defaultValue={progress.contact_name}
                  onChange={(e) => (progress.contact_name = e.target.value)}
                />
              </div>
              <div className="input-group">
                <label htmlFor="contact_tel">
                  Your Position<span className="required">*</span>
                </label>
                <input
                  type="text"
                  required
                  id="contact_position"
                  name="contact_position"
                  className="cm-input"
                  defaultValue={progress.contact_position}
                  onChange={(e) => (progress.contact_position = e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 md-2 md:mb-4">
              <div className="input-group">
                <label htmlFor="contact_email">
                  Your Email<span className="required">*</span>
                </label>
                <input
                  type="email"
                  required
                  id="contact_email"
                  name="contact_email"
                  className="cm-input"
                  defaultValue={progress.contact_email}
                  onChange={(e) => (progress.contact_email = e.target.value)}
                />
              </div>
              <div className="input-group">
                <label htmlFor="contact_tel">
                  Your Phone Number<span className="required">*</span>
                </label>
                <input
                  type="tel"
                  required
                  id="contact_tel"
                  name="contact_tel"
                  className="cm-input"
                  defaultValue={progress.contact_tel}
                  onChange={(e) => (progress.contact_tel = e.target.value)}
                />
              </div>
            </div>
            <div className="input-group">
              <label htmlFor="hear_about_us">
                Where did you hear about us?
                <span className="required">*</span>
              </label>
              <select
                required
                id="hear_about_us"
                name="hear_about_us"
                className="cm-input"
                value={hearAboutUs}
                onChange={(e) => {
                  setHearAboutUs(e.target.value)
                  quote.marketing.hearAboutUs = e.target.value
                  progress.marketing.hearAboutUs = e.target.value
                  quote.marketing.hearAboutUsOther = ''
                  progress.marketing.hearAboutUsOther = ''
                  if (e.target.value == 'Other') {
                    setShowOther(true)
                  } else {
                    setShowOther(false)
                  }
                }}
              >
                <option value="--" disabled>
                  Select an option
                </option>
                <option value="Recommendation from another school">
                  Recommendation from another school
                </option>
                <option value="Email or letter from Schools Advisory Service">
                  Email or letter from Schools Advisory Service
                </option>
                <option value="Online Search Engine">
                  Online Search Engine
                </option>
                <option value="Met at a Conference or Event">
                  Met at a Conference or Event
                </option>
                <option value="Met at a Cluster Meeting/School Forum">
                  Met at a Cluster Meeting/School Forum
                </option>
                <option value="Previously insured with SAS">
                  Previously insured with SAS
                </option>
                <option value="Had a quote from SAS in the past">
                  Had a quote from SAS in the past
                </option>
                <option value="Via another partner or association">
                  Via another partner or association
                </option>
                <option value="Other">Other</option>
              </select>
              {showOther && (
                <div className="input-group mt-2">
                  <label htmlFor="marketingOther">
                    Other<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    placeholder="Where did you hear about us?"
                    id="marketingOther"
                    name="marketingOther"
                    className="cm-input"
                    defaultValue={quote.marketing.hearAboutUsOther}
                    onChange={(e) => {
                      quote.marketing.hearAboutUsOther = e.target.value
                      progress.marketing.hearAboutUsOther = e.target.value
                    }}
                  />
                </div>
              )}
            </div>
            <div className="flex justify-end items-center mt-8">
              {selected.name && selected.name !== '' ? (
                <button className="btn w-full bg-primary hover:bg-primary-hover text-white">
                  Next: Your Staff Categories
                </button>
              ) : (
                <p className="text-center w-full font-bold text-primary text-xl">
                  Please pick a{' '}
                  {quote.type === 'single' ? 'School/Academy ' : 'Trust '}
                  before you continue
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 py-8 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-3xl font-bold leading-6 text-slate-800 mb-6"
                  >
                    An Error Occured
                  </Dialog.Title>
                  <div className="mt-2 text-base">
                    Please make sure you fill in all the options in the contact
                    details section.
                  </div>

                  <div className="mt-8 flex justify-end">
                    <button
                      className="btn bg-blue-500 hover:bg-blue-600 text-white"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default Step1
