import Logo from '../assets/logo.png'
import LogoSmall from '../assets/logo-small.png'

import { Disclosure } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faCheckCircle,
  faAnglesLeft,
} from '@fortawesome/free-solid-svg-icons'

import useGlobal from '../hooks/useGlobal'

const LeftBar = () => {
  const { steps, goToStep, sidebarCollapsed, setSidebarCollapsed } = useGlobal()

  const currentStep = () => {
    return steps.find((step) => step.current === true)
  }

  const stepAvailable = (step) => {
    if (steps[step].status === 'complete') {
      return true
    }
    if (step > 0) {
      return steps[step - 1].status === 'complete'
    }
    return false
  }

  const stepIcon = (step) => {
    switch (steps[step].status) {
      case 'incomplete':
        return 'text-[#d9d9d9]'
      case 'in-progress':
        return 'text-[#D6B42C]'
      case 'complete':
        return 'text-[#3DB16F]'
      default:
        return 'text-[#d9d9d9]'
    }
  }

  const statusTitle = (status) => {
    if (status === 'incomplete') {
      return 'Incomplete'
    }
    if (status === 'in-progress') {
      return 'In Progress'
    }
    if (status === 'complete') {
      return 'Complete'
    }
  }

  const resetQuote = async () => {
    await localStorage.removeItem('quote')
    await localStorage.removeItem('progress')
    await localStorage.removeItem('deletedSchools')
    await localStorage.removeItem('steps')
    window.location.reload()
  }

  return (
    <div
      className={`${
        sidebarCollapsed ? 'w-24' : 'w-1/5'
      } bg-cm-gray shadow-xl h-screen fixed transition-all duration-200 whitespace-nowrap overflow-hidden`}
    >
      <FontAwesomeIcon
        onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
        icon={faAnglesLeft}
        className={`hidden mid:flex top-3 left-3 absolute text-slate-600 cursor-pointer transform ${
          sidebarCollapsed ? 'rotate-180' : ''
        }`}
      />
      {sidebarCollapsed ? (
        <div>
          <div className="px-5 min-h-[80px] flex items-center justify-center border-b border-b-gray-300 mt-6">
            <img src={LogoSmall} alt="Schools Mutual Logo" className="w-16" />
          </div>
        </div>
      ) : (
        <div className="px-10 py-8 min-h-[120px] flex items-center justify-center border-b border-b-gray-300">
          <img src={Logo} alt="Schools Mutual Logo" className="w-24" />
        </div>
      )}

      <div>
        {sidebarCollapsed ? (
          <ul>
            {steps?.map((step, index) => {
              if (index === 0 || index === 5) return null
              return (
                <li
                  onClick={() => {
                    if (stepAvailable(index)) {
                      goToStep(step.id)
                    }
                  }}
                  key={index}
                  title={statusTitle(step.status)}
                  className={`px-2 py-3 text-sm w-full text-left font-semibold text-font-gray flex justify-between items-center ${
                    step.current ? 'bg-[#e0e0e0]' : ''
                  } ${
                    stepAvailable(index)
                      ? 'cursor-pointer hover:bg-primary-dark hover:text-white'
                      : ''
                  }`}
                >
                  <span>Step {index}</span>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className={`text-sm ${stepIcon(index)}`}
                  />
                </li>
              )
            })}
          </ul>
        ) : (
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="p-4 w-full text-left font-semibold text-font-primary bg-gray-100 flex justify-between items-center">
                  <span>Current: {currentStep().name}</span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={open ? 'rotate-180 transform' : ''}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="text-gray-500">
                  <ul>
                    {steps?.map((step, index) => {
                      if (index === 0 || index === 5) return null
                      return (
                        <li
                          onClick={() => {
                            if (stepAvailable(index)) {
                              goToStep(step.id)
                            }
                          }}
                          key={index}
                          title={statusTitle(step.status)}
                          className={`p-3  w-full text-left font-semibold text-font-gray flex justify-between items-center ${
                            step.current ? 'bg-[#e0e0e0]' : ''
                          } ${
                            stepAvailable(index)
                              ? 'cursor-pointer hover:bg-primary-dark hover:text-white'
                              : ''
                          }`}
                        >
                          <span>{step.name}</span>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className={`text-xl ${stepIcon(index)}`}
                          />
                        </li>
                      )
                    })}
                  </ul>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        )}
      </div>

      <p
        onClick={resetQuote}
        className="absolute bottom-0 w-full py-3 bg-primary-dark text-center text-xs font-normal text-white  hover:bg-primary-dark-hover cursor-pointer"
      >
        Reset Quote
      </p>
    </div>
  )
}

export default LeftBar
