import { Outlet } from 'react-router-dom'
import LeftBar from '../components/LeftBar'
import Header from '../components/Header'
import Footer from '../components/Footer'
import useGlobal from '../hooks/useGlobal'

const MainLayout = () => {
  const { sidebarCollapsed } = useGlobal()

  const resetQuote = async () => {
    await localStorage.removeItem('quote')
    await localStorage.removeItem('progress')
    await localStorage.removeItem('deletedSchools')
    await localStorage.removeItem('steps')
    window.location.reload()
  }

  return (
    <div className="relative flex min-h-screen bg-[#F5F5F5] w-full mx-auto overflow-x-hidden">
      <div className="hidden lg:block">
        <LeftBar />
      </div>
      <main
        className={`h-full w-full ${
          sidebarCollapsed ? 'lg:pl-24' : 'lg:pl-[20%]'
        } transition-all duration-200`}
      >
        <Header />
        <div className="pb-10 md:min-h-[600px] px-2 md:px-6">
          <Outlet />
        </div>
        <p
          onClick={resetQuote}
          className="inline-flex lg:hidden relative ml-4 text-sm font-normal text-white bg-primary-dark px-8 py-2 rounded hover:bg-primary-dark-hover cursor-pointer mb-4"
        >
          Reset Quote
        </p>
        <Footer />
      </main>
    </div>
  )
}

export default MainLayout
