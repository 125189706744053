/* eslint-disable */
import useGlobal from '../hooks/useGlobal'
import { useEffect, useState } from 'react'
import axios from 'axios'

const Complete = () => {
  const { progress, quote, token, api, quoteStatus } = useGlobal()

  const [showCallback, setShowCallback] = useState(false)
  const [callback, setCallback] = useState({ callDate: '', callTime: 'am' })
  const [buttonText, setButtonText] = useState('Update Enquiry')

  useEffect(() => {
    resetLocalStorage()
  }, [])

  const resetLocalStorage = async () => {
    await localStorage.removeItem('quote')
    await localStorage.removeItem('progress')
    await localStorage.removeItem('deletedSchools')
    await localStorage.removeItem('steps')
  }

  const finishQuote = async (e) => {
    e.preventDefault()
    try {
      await axios.put(api + 'enquiry/' + progress.enquiryID, {
        token: token,
        enquiry_callback: callback,
        enquiry_stage: 'complete',
        enquiry_reference: 1,
        quote_id: progress.quoteID,
      })

      setButtonText('Thanks! We will be in touch soon.')
    } catch (error) {}
  }

  return (
    <div className="w-full flex flex-col items-center px-2 md:px-0 py-6 md:py-12">
      <h2 className="text-font-primary font-bold text-xl sm:text-2xl lg:text-3xl mb-2 text-center relative">
        {quoteStatus}
      </h2>

      <div className="flex flex-col justify-center px-8 w-full max-w-2xl">
        {quoteStatus === 'Quote Complete' && (
          <div>
            <div className="checkmark fade-in">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 161.2 161.2"
                enableBackground="new 0 0 161.2 161.2"
              >
                <path
                  className="path"
                  fill="none"
                  stroke="#3dc654"
                  strokeMiterlimit="10"
                  d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
    c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
    c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"
                />
                <circle
                  className="path"
                  fill="none"
                  stroke="#3dc654"
                  strokeWidth="4"
                  strokeMiterlimit="10"
                  cx="80.6"
                  cy="80.6"
                  r="62.1"
                />
                <polyline
                  className="path"
                  fill="none"
                  stroke="#3dc654"
                  strokeWidth="6"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  points="113,52.8 
    74.1,108.4 48.2,86.4 "
                />

                <circle
                  className="spin"
                  fill="none"
                  stroke="#3dc654"
                  strokeWidth="4"
                  strokeMiterlimit="10"
                  strokeDasharray="12.2175,12.2175"
                  cx="80.6"
                  cy="80.6"
                  r="73.9"
                />
              </svg>
            </div>

            <h3 className="text-xl sm:text-2xl lg:text-3xl font-bold text-center text-primary mt-4">
              Thank you for your quote request. One of our team will be in
              contact shortly.
            </h3>

            <div className="input-group max-w-sm mx-auto mt-6">
              <label htmlFor="noSchool-contact_tel">Reference Number</label>
              <input
                type="text"
                id="noSchool-contact_tel"
                name="noSchool-contact_tel"
                className="cm-input disabled"
                defaultValue={quote.referenceNumber}
              />
            </div>

            <div className="flex flex-col sm:flex-row justify-between max-w-sm sm:max-w-lg mt-8 mx-auto w-full">
              <a
                role="button"
                className="btn bg-emerald-500 hover:bg-emerald-600 text-white mb-4 md:mb-0 text-center"
                href="tel:01773814400"
              >
                Talk to us on 01773 814400
              </a>

              <button
                className="btn bg-emerald-500 hover:bg-emerald-600 text-white"
                onClick={() => setShowCallback(!showCallback)}
              >
                Request a Call Back
              </button>
            </div>

            {showCallback === true && (
              <form
                className="flex flex-col items-center"
                onSubmit={finishQuote}
              >
                <div className="input-group max-w-sm mx-auto mt-6">
                  <label htmlFor="callback_date">Callback Date</label>
                  <input
                    type="date"
                    required
                    id="callback_date"
                    name="callback_date"
                    className="cm-input"
                    defaultValue={callback.callDate}
                    onChange={(e) =>
                      setCallback({ ...callback, callDate: e.target.value })
                    }
                  />
                </div>

                <div className="input-group max-w-sm mx-auto mt-6">
                  <label htmlFor="callback_time">Callback Time</label>
                  <select
                    required
                    id="callback_time"
                    name="callback_time"
                    className="cm-input"
                    defaultValue={callback.callTime}
                    onChange={(e) =>
                      setCallback({ ...callback, callTime: e.target.value })
                    }
                  >
                    <option value="am">AM</option>
                    <option value="pm">PM</option>
                  </select>
                </div>

                <button
                  type="submit"
                  className={`btn small mt-4 bg-blue-500 hover:bg-blue-600 text-white ${
                    buttonText === 'Thanks! We will be in touch soon.'
                      ? 'pointer-events-none'
                      : ''
                  }`}
                >
                  {buttonText}
                </button>
              </form>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Complete
