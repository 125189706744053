/* eslint-disable */
import moment from 'moment/moment'
import useGlobal from '../hooks/useGlobal'
import { useEffect, useState } from 'react'

const Step4 = () => {
  const { progress, quote, setQuote, saveProgress, goToStep } = useGlobal()

  const [updateAllItems, setUpdateAllItems] = useState({
    coverStartDate: moment().format('YYYY-MM-DD'),
    coverEndDate: '',
  })

  let [showUpdateAll, setShowUpdateAll] = useState(false)

  const nextStep = async (e) => {
    save()
  }

  useEffect(() => {
    updateSchoolDates()
  }, [])

  const updateSchoolDates = () => {
    let tempSchools = [...quote.schools]

    tempSchools.forEach((school) => {
      school.coverStartDate = moment().format('YYYY-MM-DD')

      school.coverEndDate = moment()
        .add(1, 'years')
        .subtract(1, 'days')
        .format('YYYY-MM-DD')
    })

    setQuote({ ...quote, schools: tempSchools })
  }

  const save = () => {
    saveProgress()
    goToStep(5, 'complete')
  }

  const updateEverything = (start, end) => {
    let temp = [...quote.schools]
    temp.forEach((school) => {
      school.coverStartDate = start
      school.coverEndDate = end
    })

    setQuote({ ...quote, schools: temp })
  }

  return (
    <div className="w-full flex flex-col items-center px-2 md:px-0 py-6 md:py-12">
      <h2 className="text-font-primary font-bold text-xl sm:text-2xl lg:text-3xl mb-8 text-center relative">
        When should your scheme start?
      </h2>

      {quote.type === 'multi' && (
        <>
          <div className="flex w-full items-center justify-start px-2 md:px-8 mt-4 mb-8">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={showUpdateAll}
                className="sr-only peer"
                id="update-all-schools"
                onChange={(e) => {
                  setShowUpdateAll(e.target.checked)
                }}
              />
              <div className="w-11 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary"></div>
            </label>
            <label
              htmlFor="update-all-schools"
              className="ml-4 text-sm md:text-base lg:text-lg cursor-pointer"
            >
              Update all Schools/Academies at once
            </label>
          </div>

          {showUpdateAll && (
            <div className="bg-white w-full py-6 px-8 mb-8">
              <div>
                <h3 className="text-primary font-semibold text-xl text-center">
                  Set Scheme Start Date for All Schools/Academies
                </h3>
                <div className={`w-full mb-8`}>
                  <div className=" p-4 rounded-lg h-full max-w-sm w-full mx-auto">
                    <div className="input-group">
                      <label htmlFor="school-start_date">
                        Scheme Start Date
                      </label>
                      <input
                        type="date"
                        required
                        id="school-start_date"
                        name="school-start_date"
                        className="cm-input w-full"
                        defaultValue={updateAllItems.coverStartDate}
                        onChange={(e) => {
                          let schools = { ...updateAllItems }
                          schools.coverStartDate = moment(
                            e.target.value
                          ).format('YYYY-MM-DD')
                          schools.coverEndDate = moment(e.target.value)
                            .add(1, 'years')
                            .subtract(1, 'days')
                            .format('YYYY-MM-DD')
                          setUpdateAllItems(schools)
                          updateEverything(
                            moment(e.target.value).format('YYYY-MM-DD'),
                            moment(e.target.value)
                              .add(1, 'years')
                              .subtract(1, 'days')
                              .format('YYYY-MM-DD')
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <form
        className={`flex flex-col justify-center px-2 md:px-8 w-full ${
          quote.type == 'single' ? 'max-w-3xl' : 'max-w-7xl'
        }`}
        onSubmit={nextStep}
      >
        <div className="flex w-full flex-wrap">
          {quote.schools.map((school, index) => {
            return (
              <div
                className={`${
                  quote.type === 'single'
                    ? 'w-full'
                    : 'w-full small:w-1/2 lg:w-1/3 px-2'
                } mb-4`}
                key={index}
              >
                <div className="bg-slate-200/40 p-4 rounded-lg h-full">
                  <h3 className="text-primary font-semibold text-base md:text-xl mb-4">
                    {school.name}
                  </h3>

                  <div className="input-group">
                    <label htmlFor="school-start_date">Scheme Start Date</label>
                    <input
                      type="date"
                      required
                      id="school-start_date"
                      name="school-start_date"
                      className="cm-input"
                      value={school.coverStartDate}
                      onChange={(e) => {
                        let val = e.target.value
                        let schools = [...quote.schools]
                        schools[index].coverStartDate = moment(
                          e.target.value
                        ).format('YYYY-MM-DD')
                        schools[index].coverEndDate = moment(e.target.value)
                          .add(1, 'years')
                          .subtract(1, 'days')
                          .format('YYYY-MM-DD')
                        setQuote({ ...quote, schools: schools })
                      }}
                    />
                  </div>

                  <div className="input-group mt-4">
                    <label htmlFor="school-start_date">
                      Additional Requirements
                    </label>
                    <textarea
                      id="school-additional"
                      name="school-additional"
                      className="cm-input py-2"
                      style={{
                        minHeight: 100,
                        maxHeight: 200,
                        minWidth: '100%',
                        maxWidth: '100%',
                      }}
                      defaultValue={school.additionalCover}
                      onChange={(e) =>
                        (school.additionalCover = e.target.value)
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <hr className="border-slate-300 w-full my-8 mx-auto" />

        <h2 className="text-font-primary font-bold text-xl sm:text-2xl lg:text-3xl mb-8 text-center relative">
          Tell us about your current policy
        </h2>

        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 mb-2 md:mb-8 max-w-3xl">
          <div className="input-group">
            <label htmlFor="marketing-insurer">
              Who is your current insurer?
            </label>
            <input
              type="text"
              id="marketing-insurer"
              name="marketing-insurer"
              className="cm-input"
              placeholder="Optional"
              defaultValue={progress.marketing.currentInsurer}
              onChange={(e) => {
                quote.marketing.currentInsurer = e.target.value
                progress.marketing.currentInsurer = e.target.value
              }}
            />
          </div>
          <div className="input-group">
            <label htmlFor="marketing-premium">
              What is your current premium total?
            </label>
            <input
              type="text"
              id="marketing-premium"
              name="marketing-premium"
              className="cm-input"
              placeholder="Optional"
              defaultValue={progress.marketing.currentPremium}
              onChange={(e) => {
                quote.marketing.currentPremium = e.target.value
                progress.marketing.currentPremium = e.target.value
              }}
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 mb-8 md:mb-8 max-w-3xl">
          <div className="input-group">
            <label htmlFor="marketing-referral">Discount/Referral Code</label>
            <input
              type="text"
              id="marketing-referral"
              name="marketing-referral"
              className="cm-input"
              placeholder="Optional"
              defaultValue={progress.marketing.referralCode}
              onChange={(e) => {
                quote.marketing.referralCode = e.target.value
                progress.marketing.referralCode = e.target.value
              }}
            />
          </div>
        </div>

        <button className="btn w-full bg-primary hover:bg-primary-hover text-white max-w-3xl mx-auto">
          Get Your Quote
        </button>
      </form>
    </div>
  )
}

export default Step4
