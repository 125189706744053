/* eslint-disable */
import useGlobal from '../hooks/useGlobal'
import { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ToolTip from '../components/ToolTip'
import {
  faPlus,
  faSave,
  faTimes,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'

const Step2 = () => {
  const { quote, setQuote, saveProgress, goToStep } = useGlobal()

  const [showAddType, setShowAddType] = useState(false)
  const [tempAddType, setTempAddType] = useState('')

  const nextStep = async () => {
    save()
  }

  const save = () => {
    saveProgress()
    goToStep(3, 'complete')
  }

  const removeStaffTypeItem = (i, n) => {
    const tempArr = [...quote.schools]
    tempArr[i].items.splice(n, 1)
    setQuote({ ...quote, schools: tempArr })
  }

  const removeSchoolItem = (i) => {
    const tempArr = [...quote.schools]
    tempArr.splice(i, 1)
    setQuote({ ...quote, schools: tempArr })
  }

  const addNewType = (i) => {
    if (tempAddType.length === 0) return

    const tempArr = [...quote.schools]

    tempArr[i].items.push({
      breakdown: {
        base: 0,
        beforeAutoDiscount: 0,
        withAutoDiscount: 0,
        premium: 0,
        maternity: '0.00',
      },
      staffType: tempAddType,
      staffGrade: 'B',
      selected: true,
      benefit: 0,
      fte: '0.00',
      excess: '--',
      period: '--',
      stress: '--',
      maternity: '0.00',
      maternityCover: 'Excluded',
      premium: 0,
    })

    setQuote({ ...quote, schools: tempArr })
    setShowAddType(false)
    setTempAddType('')
  }

  return (
    <div className="w-full flex flex-col items-center px-2 md:px-0 py-6 md:py-12">
      <h2 className="text-font-primary font-bold text-xl sm:text-2xl lg:text-3xl mb-8 text-center relative">
        Staff to be included
        <ToolTip
          text="Confirm the staff categories you wish to include. If additional staffing groups are required please click the '+ Add Staff Type' and insert the name of the staffing group. NB: You do not need to include all the staff in the staffing group listed. On the next sheet you can identify the number of staff per category to include."
          icon="right"
          popup="right"
        />
      </h2>

      <div
        className={`flex flex-col justify-center ${
          quote.type === 'single' ? 'max-w-3xl' : 'max-w-5xl'
        } md:px-4 w-full `}
      >
        <div className="w-full flex flex-wrap mb-10">
          {quote.schools.map((school, index) => {
            return (
              <div
                className={`mb-4  ${
                  quote.type === 'single'
                    ? 'w-full'
                    : 'w-full small:w-1/2 lg:w-1/3 px-2'
                }`}
                key={index}
              >
                <div className="bg-slate-200/40 p-4 rounded-lg h-full">
                  <h3 className="text-primary font-bold text-lg">
                    {school.name}{' '}
                    {quote.type !== 'single' && (
                      <span
                        className="cursor-pointer ml-4 text-base"
                        onClick={() => removeSchoolItem(index)}
                      >
                        <>
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            className="text-red-500"
                            title="Remove academy from quote"
                          />
                        </>
                      </span>
                    )}
                  </h3>

                  <div className="mt-6">
                    {school.items.map((item, itemIndex) => {
                      return (
                        <div className="flex items-center mb-1" key={itemIndex}>
                          <p className="font-bold">{item.staffType}</p>
                          {itemIndex > 0 && (
                            <span
                              className="cursor-pointer ml-4"
                              onClick={() =>
                                removeStaffTypeItem(index, itemIndex)
                              }
                            >
                              <>
                                <FontAwesomeIcon
                                  icon={faTrashCan}
                                  className="text-red-500 text-sm"
                                  title="Remove Staff Category"
                                />
                              </>
                            </span>
                          )}
                        </div>
                      )
                    })}
                    <div className="mt-6">
                      <div
                        className="mb-2 flex items-center cursor-pointer"
                        onClick={() => setShowAddType(index)}
                      >
                        <span className="rounded bg-primary w-6 h-6 flex justify-center items-center">
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="text-white text-sm"
                          />
                        </span>
                        <p className="text-sm ml-4">Add New Category</p>
                      </div>
                      {showAddType === index && (
                        <div className="flex items-center">
                          <input
                            type="text"
                            required
                            id={`add_type_${index}`}
                            name={`add_type_${index}`}
                            className="cm-input max-w-[250px]"
                            onChange={(e) => setTempAddType(e.target.value)}
                          />
                          <span
                            onClick={() => addNewType(index)}
                            className={`rounded bg-emerald-500 w-8 h-8 flex justify-center items-center ml-4 ${
                              tempAddType.length === 0
                                ? 'opacity-70 cursor-no-drop'
                                : 'cursor-pointer hover:bg-emerald-600'
                            }`}
                          >
                            <FontAwesomeIcon
                              icon={faSave}
                              className="text-white"
                            />
                          </span>
                          <span
                            className="rounded bg-red-500 w-8 h-8 flex justify-center items-center ml-2 cursor-pointer"
                            onClick={() => setShowAddType(false)}
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="text-white"
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <button
          className="btn w-full bg-primary hover:bg-primary-hover text-white max-w-3xl mx-auto"
          onClick={nextStep}
        >
          Next: Your Scheme
        </button>
      </div>
    </div>
  )
}

export default Step2
